import {index} from './index';

export function loading(){
    if(document.getElementById('intro')){

        const images = document.getElementsByTagName('img');
        const loading = document.getElementById('intro');
        const bar = document.getElementById('bar');
        const txt = document.getElementById('num');
        
        let percent = 0;
        let finish = false;
        let leng = images.length;
        let imgCount = 0;
        let baseCount = 0;
        let current;

        for (let i = 0; i < images.length; i++) {
          let img = new Image();
          img.onload = function() {
              imgCount += 1;
          }
          img.onerror = function() {
              imgCount += 1;
          }
          img.src = images[i].src;
        };

        function disp(result){
            setTimeout(function(){
                let num = percent;
                if(result > num | num < 101){
                    percent = num +1;
                    bar.style.width = num + '%';
                    txt.innerHTML = num;
                    disp(result);
                }else{
                    nowLoading();
                }

                if(num == 100){
                    if(!finish){
                        finish = true;
                        setTimeout(function(){
                            loading.classList.add('active');
                            setTimeout(function(){
                                loading.style.display = 'none';
                            },600); 
                            index();
                        },600);
                    }
                }
            },8);
        }
        function nowLoading(){
          if(baseCount <= imgCount) {
              current = Math.floor(baseCount / leng * 100);
              disp(current);
              baseCount ++;
          }
        }

        if(leng == 0){
            loading.style.display = 'none';
        }else{
            nowLoading();
        }
    }
}