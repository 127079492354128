import Swiper from 'swiper';

export function index(){
    const fv = document.getElementById('fv');
    if(fv){
        fv.classList.add('active');
        const video = document.getElementById('video');
        video.play();
    }
    
    const indexswipe =  document.getElementById('index-swipe');
    if(indexswipe){
        let plam = {
            autoplay: {
            	delay: 5000,
            	disableOnInteraction: false,
            },
            effect: 'fade',
            slidesPerView: 1,
            centeredSlides: false,
            speed: 4000,
            spaceBetween: 0,
            loop: true,
            pagination: {
                el: '#index-swipe-pager',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>';
                },
            },
			breakpoints: {
				768: {
					slidesPerView: 1,
					spaceBetween: 30,
					centeredSlides: false
				}
			}
        };
        new Swiper('#index-swipe' , plam);
    }
}