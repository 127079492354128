import ScrollMagic from 'scrollmagic';
import Rellax from 'rellax';

export function scrolEvents(controller , scene){
    if(document.getElementById('site-header')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: 500
            })
            //.addIndicators()
            .setClassToggle('#site-header','active').addTo(controller)
        );
    }

    if(document.getElementById('pagetop')){
        scene.push(
            new ScrollMagic.Scene({
                triggerElement: 'body',
                offset: window.innerHeight * 1.5
            })
            //.addIndicators()
            .setClassToggle('#pagetop','active').addTo(controller)
        )
    }
	
	const scroll_anime_name = 'scroll-anime';
	const scroll_anime_elem = document.getElementsByClassName(scroll_anime_name);
	if(scroll_anime_elem.length > 0){
		for(let i = 0; i < scroll_anime_elem.length; i++){
			let scroll_node = scroll_anime_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(scroll_node,'_active').addTo(controller)
			)
		}
	}
    
	const scroll_custum_name = 'scroll-custum';
	const scroll_custum_elem = document.getElementsByClassName(scroll_custum_name);
	if(scroll_custum_elem.length > 0){
		for(let i = 0; i < scroll_custum_elem.length; i++){
			let custum_node = scroll_custum_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: custum_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(custum_node,'_active').addTo(controller)
			)
		}
	}
	
	const scroll_img_name = 'scroll-img';
	const scroll_img_elem = document.getElementsByClassName(scroll_img_name);
	if(scroll_img_elem.length > 0){
		for(let i = 0; i < scroll_img_elem.length; i++){
			let scroll_img_node = scroll_img_elem[i];
			scene.push(
				new ScrollMagic.Scene({
					triggerElement: scroll_img_node,
					offset: -300
				})
				//.addIndicators()
				.reverse(false)
				.setClassToggle(scroll_img_node,'__active').addTo(controller)
			)
		}
	}

    // RELLAX
    if(document.getElementsByClassName('rellax').length > 0){
        const rellax = new Rellax('.rellax',{
            center:true
        });
    }
    if(document.getElementsByClassName('rellax-no').length > 0){
        const rellax_no = new Rellax('.rellax-no',{
            center:false
        });
    }

    
    const scroll = new SmoothScroll('a[href*="#"], [data-scroll]',{
      offset: 0
    });
    
    const slice_text = document.getElementsByClassName('txt_slice');
    for(let i = 0; i < slice_text.length; i++){
        slicer(slice_text[i]);
    }

    function slicer(elem){
        const txtchange =  elem.innerHTML.replace(/<br>/g, '_');
        const txt =  txtchange;
        elem.innerHTML = '';
        txt.split('').forEach(function(c) {
            if(c == '_'){
                elem.innerHTML += '<br>';
            }
            else if(c == ' '){
                elem.innerHTML += '<span>&nbsp;</span>';
            }
            else{
                elem.innerHTML += '<span>'+c+'</span>';
            }
        });
    }

    const second = document.getElementById('page-title');
    if(second){
        second.classList.add('active');
    }
    
    const particles = document.getElementById('particles')
    if(particles){
      particlesJS("particles", {
        particles: {
          number: {
            value: 20,
            density: { enable: true, value_area: 2683.1443294901214 }
          },
          color: { value: "#f43300" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
            polygon: { nb_sides: 5 },
            image: { src: "img/github.svg", width: 100, height: 100 }
          },
          opacity: {
            value: 1,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: false,
            distance: 500,
            color: "#ffffff",
            opacity: 0.4,
            width: 2
          },
          move: {
            enable: true,
            speed: 32,
            direction: "right",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: false, mode: "bubble" },
            onclick: { enable: false, mode: "repulse" },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 0.5 } },
            bubble: { distance: 400, size: 4, duration: 0.3, opacity: 1, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
    }

}