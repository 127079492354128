import Swiper from 'swiper';

export function company(){
    const s = document.getElementById('solution');
    if(s){
        const swiper = new Swiper('#solution', {
            spaceBetween: 30,
            effect: 'fade',
            loop: true,
            navigation: {
                nextEl: '.solution-control-next',
                prevEl: '.solution-control-prev',
            },
        });
    }
}